import { isArray } from "lodash-es";
import { saveAs } from 'file-saver';
import moment from 'moment-timezone'
import { isEmpty } from "lodash";


export const traduccion = {
    "allItemsAreSelected": "Todos los item seleccionados",
    "clearSearch": "Limpiar Filtro",
    "clearSelected": "Limpiar Seleccion",
    "noOptions": "No hay opciones",
    "search": "Filtrar",
    "selectAll": "Seleccionar Todos",
    "selectAllFiltered": "Seleccionar Todos (Filtrados)",
    "selectSomeItems": "Seleccionar...",
    "create": "Crear",
}


const makePrefix = (prefix:any,registro:any) => {
    if (!isEmpty(prefix)) {
        if ((prefix?.keyField) && (registro[prefix?.keyField])) {
            if ((prefix?.options) && (prefix?.options[registro[prefix?.keyField]])){
                return prefix?.options[registro[prefix?.keyField]]
            }
            else{
                return ''
            }
        }
        else if (prefix?.all) {
            return (prefix?.all)
        }
        else {
            return ''
        }
    }
    else
        return '';
}


export const formatOptions = (result: any, entidad: string, fieldLabel: string, fieldValue: string, fieldActive: string, dataField: string[], formatStrLabel = "%s", prefix={}) => {
    let labels = fieldLabel.split(',')
    return ((result.data[entidad] === undefined) || (result.data[entidad] === null)) ? [] : result.data[entidad].data.map((registro: any) => {
        const objeto = Object.create({});
        //objeto.label = registro[fieldLabel]
        //objeto.label = format_array(registro, labels);
        objeto.label = makePrefix(prefix, registro) + arrayFormatStr(formatStrLabel || '%s', formatArray(registro, labels))
        objeto.value = registro[fieldValue]
        objeto.disabled = ((fieldActive) && (registro[fieldActive] !== undefined)) ? registro[fieldActive] : 0
        if (dataField) {
            if (Array.isArray(dataField)) {
                dataField.forEach((item) => {
                    objeto[item] = registro[item]
                })
            }
            else if (dataField !== "") {
                objeto[dataField] = registro[dataField]
            }
        }
        return objeto;
    })
}

export const arrayFormatStr = (str: string, variables: any) => {
    variables = isArray(variables) ? variables : variables.split(',');
    variables.forEach((item: any) => { str = str.replace(/%[a-z]/, (item ? item : "")); })
    return str;
}

export const formatArray = (registro: any, labelArray: string[]) => {
    return labelArray.map(item => registro[item])
}

export const formatearString = (fields: any) => {
    let str = ''
    fields.forEach((field: any, index: any) => {
        str = str.concat((index === 0) ? `${(field.format) ? (field.format) : "%s"}` : `${(field.separador) ? (field.separador) : ''}${(field.format) ? (field.format) : "%s"}`)
    });
    return str;
}

export function hexToRgbA(hex: any, perc: any) {
    var c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + perc + ')';
    }
    throw new Error('Bad Hex');
}

export const Colors: {
    'names': { [key: string]: string },
    'random': any,
    'getColor': any
} = {
    'names': {
        red: "#ff0000",
        lime: "#00ff00",
        blue: "#0000ff",
        gold: "#ffd700",
        cyan: "#00ffff",
        magenta: "#ff00ff",
        navy: "#000080",
        green: "#008000",
        maroon: "#800000",
        indigo: "#008080",
        olive: "#808000",
        violet: "#800080",
        orange: "#ffa500",
        pink: "#ffc0cb",
        silver: "#c0c0c0",
    },
    'random': function () {
        var result: any;
        var count = 0;
        for (var prop in this.names)
            if (Math.random() < 1 / ++count)
                result = prop;
        return { name: result, rgb: this.names[result] };
    },
    'getColor': function (lista: any) {
        var result: any;
        var enc = false;
        for (var prop in this.names)
            if (lista.indexOf(this.names[prop]) === -1) {
                result = prop;
                enc = true;
                break;
            }
        return { name: (enc) ? result : "Color_Agotado", hex: (enc) ? this.names[result] : "#c1c1c1" }
    }
};

export const pastelColors: {
    'names': { [key: string]: string },
    'getColor': any
} = {
    'names': {
        red: "#dd7f5a",
        blue: "#5d97d1",
        green: "#95d387",
        yellow: "#ffe45e",
        lime: "#ffd670",
        gold: "#ffe1af",
        cyan: "#b5deff",
        magenta: "#986d8e",
        navy: "#316b83",
        maroon: "#9e7777",
        indigo: "#6b7aa1",
        olive: "#cee5d0",
        violet: "#cab8ff",
        orange: "#ffc898",
        pink: "#ffdefa",
        silver: "#6d8299",
    },
    'getColor': function (lista: any) {
        var result: any;
        var enc = false;
        for (var prop in this.names)
            if (lista.indexOf(this.names[prop]) === -1) {
                result = prop;
                enc = true;
                break;
            }
        return { name: (enc) ? result : "Color_Agotado", hex: (enc) ? this.names[result] : "#6d8299" }
    }
};

export const newColors: {
    'names': { [key: string]: string },
    'getColor': any
} = {
    'names': {
        B746A2:"#2192FF",
        L2ca9d:"#82ca9d",        
        FBDF07:"#FBCB0A",
        ff8080:"#FF4A4A",
        F1CA89:"#F1CA89",
        B0DFEF:"#40DFEF",
        B8B5FF:"#B8B5FF",
        NFBCD2:"#7FBCD2",
        maroon:"#CC9B6D",
        indigo:"#93B5C6",
        olive: "#CFDAC8",
        V868E6:"#7868E6",
        orange:"#FFAE6D",
        G5C18C:"#65C18C",
        FFBED8:"#FFBED8",
        C9CCD5:"#C9CCD5",
        FFEEAF:"#FFEEAF",
        C5F4FF:"#85F4FF",
        C689C6:"#C689C6",
        yellow:"#ffc658",
        FA7070:"#FA7070",
        E2C2B9:"#E2C2B9",
        green2:"#28FFBF",
        morado:"#4C4C6D",
        FFE194:"#FFE194",
        ECA3F5:"#ECA3F5",
        EDFFA9:"#EDFFA9",
        FF7F3F:"#FF7F3F",
        FFDAAF:"#FFDAAF",
        GEB23B:"#9EB23B",
        BD77FF:"#4D77FF"






        
        
        
        
        
        


    },
    'getColor': function (lista: any) {
        var result: any;
        var enc = false;
        for (var prop in this.names)
            if (lista.indexOf(this.names[prop]) === -1) {
                result = prop;
                enc = true;
                break;
            }
        return { name: (enc) ? result : "Color_Agotado", hex: (enc) ? this.names[result] : "#6d8299" }
    }
};


export const saveCanvas = (id: string) => {
    //save to png
    const canvasSave = document.getElementById(id) as HTMLCanvasElement;
    canvasSave?.toBlob(function (blob: any) {
        saveAs(blob, `${id}.png`)
    })
}

export const saveCSV = (csv: any, name: string) => {
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(csvData, `${name}.csv`);
}

export const exportCSV = (columns: any, data: any, fileName: string) => {
    saveCSV(makeCSV(columns, data), fileName);
}

export const makeCSV = (columns: any, data: any) => {
    console.log(data)
    var dataFields = columns.map((columna: any) => {
        let obj: { name: string, typeField: string } = { name: '', typeField: '' };
        obj.name = columna.dataField;
        obj.typeField = columna.typeField;
        return obj
    });
    return [
        [...columns.map((columna: any) => columna.text)],
        ...data.map((registro: any) => { return dataFields.map((field: any) => `"${fieldFormat(field.typeField, registro[field.name])}"`) })
    ]
        .map(e => e.join(","))
        .join("\n");
}

export const fieldFormat = (typeField: any, value: any) => {
    if (typeField) {
        if (typeField === 'DateTime') {
            return moment(value).tz('America/Santiago').format('YYYY-MM-DD HH:mm');
        }
        else if (typeField === 'Number') {
            return (value !== null) ? `${value.toLocaleString('es-CL')}` : null;
        }
        else {
            return value;
        }
    }
    else {
        return value;
    }
}

export const isNumber = (str: string): boolean => {
    if (typeof str !== 'string') {
        return false;
    }
    if (str.trim() === '') {
        return false;
    }
    return !Number.isNaN(Number(str));
}

export const makeCSV2 = (columns: readonly any[], dataLoaded: any[], exclude: any = []) => {
    const tempHeaders = columns.filter((item: any) =>
        (exclude.indexOf((item?.accessor) ? item.accessor : item.id))
    ).map((item: any) => {
        if ((item?.exportOpts?.field) && (dataLoaded.length >0) && (Object.keys(dataLoaded[0]).includes(item.exportOpts.field)))
            return ({ label: (item?.exportOpts?.title) ? item?.exportOpts?.title : item.exportOpts.field, key: item.exportOpts.field })
        else
            return ({ label: (item?.exportOpts?.title) ? item?.exportOpts?.title : (item.accessor) ? item.accessor : item.id, key: (item.accessor) ? item.accessor : item.id })
    })
    return {
        headers: tempHeaders,
        data: dataLoaded.map(makeDataExclude(tempHeaders.map((item: any) => item.key), exclude))
    }
}

export const makeDataExclude = (props: any, excluded: any = []) => {
    return function (obj: any) {
        const newObj: any = {};
        props.forEach((name: any) => {
            if (excluded.indexOf(name) === -1) {
                newObj[name as keyof typeof newObj] = isNumber(String(obj[name as keyof typeof obj])) ? String(obj[name as keyof typeof obj]).replace('.', ',') : obj[name as keyof typeof obj]
            }
        });

        return newObj;
    }
}

export const makeData = (props: any) => {
    return function (obj: any) {
        const newObj: any = {};
        props.forEach((name: any) => {
            newObj[name as keyof typeof newObj] = obj[name as keyof typeof obj]
        });
        return newObj;
    }
}

export function filterByValue(array: any, columns: readonly any[], search: string | undefined) {
    const onlyColumns = columns.map((item: any) => /*((item?.exportOpts?.field) && (array[item?.exportOpts?.field]))?item?.exportOpts?.field:*/(item.accessor) ? item.accessor : item.id)
    return ((search) && (search !== '')) ? array.map(makeData(onlyColumns)).filter((row: any) => Object.keys(row).some((prop: any) => String(row[prop]).toLowerCase().includes(search?.toLocaleLowerCase()))) : array

    //console.log(array.filter((row:any)=>Object.keys(row)))
    //return ((search) && (search !== '')) ? array.filter((o: any) => Object.keys(o).some(k => o[k].toLowerCase().includes(search.toLowerCase()))) : array;
}

export const timeIntervals = () => {
    let time = moment().format("YYYY-MM-DD 00:00:00")
    let hora = "";
    let items:any = [];
    for (let i = 1; i <= 96; i++) {
      hora = moment(time).format("HH:mm");
      items.push({ label: hora, value: hora})
      time = moment(time).add('15', 'minutes').format("YYYY-MM-DD HH:mm:ss");
    }
    return items;
  }

export { }